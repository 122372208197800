import styled from 'styled-components';
import level from './bana.jpg';
import bird from './bird.png';
import { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { useRef } from 'react';

const StyledGame = styled.div`
    border: 1px solid red;
    height: 100vh;
    background-image: url(${level});
    background-size: auto 100%;
    background-position: ${(props) => `${props.bg}%`};
`;

const StyledBird = styled.img`
    width: 7%;
    height: auto;
    transform: translateY(${(props) => `${props.y}px`})
`
const BOOST_ITERATIONS = 4;
const DEFAULT_GRAVITY = 8

const Game = () => {
    const [background, setBackground] = useState(0);
    const [y, setY] = useState(0);
    const birdLoopRef = useRef(null)
    const [isBoosting, setIsBoosting] = useState(0)
    const [gravity, setGravity] = useState(DEFAULT_GRAVITY)

    const onTouchEnd = useCallback(() => {
        clearTimeout(birdLoopRef.current)
        setGravity(-8)
    }, [birdLoopRef])

    useEffect(() => {
        window.addEventListener('click', onTouchEnd);
        birdLoopRef.current = window.setTimeout(() => {
            if (gravity < 0 && isBoosting < BOOST_ITERATIONS) {
                setIsBoosting(prevState=>prevState + 1);
            }
            else if(gravity < 0 && isBoosting > BOOST_ITERATIONS) {
                setIsBoosting(0)
                setGravity(DEFAULT_GRAVITY)
            }
            setY(prevState => prevState + gravity);
        }, 100)

        window.setTimeout(() => {
            let newBg = background + 0.1;
            if (newBg > 100) {
                newBg = 0;
            }
            setBackground(newBg)
        }, 20)
        return () => {
            window.removeEventListener('click', onTouchEnd)
        }
    }, [background, gravity, isBoosting, onTouchEnd])

    return (
        <StyledGame bg={background}>
            <StyledBird y={y} src={bird} />
        </StyledGame>
    );
}

export default Game;
