import './App.css';
import Header from './components/Header';
import Hero from './components/Hero';
import Content404 from './components/Content404';
import styled from 'styled-components';
import Game from './components/Game/Game';

const StyledApp = styled.div`
  text-align: center;
`;

function App() {
  return (
    <StyledApp>
      <Game />
{/*       <Header />
      <Hero />
      <div className="wrapper">
        <h1>Välkommen till en kreativ plats</h1>
      </div>
      <Content404 />
      <div className="wrapper">
        <p>Här finns det tyvärr inte så mycket än så länge.</p>
      </div>
    */}
    </StyledApp>
  );
}

export default App;
