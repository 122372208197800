import React, { useEffect, useRef, useState } from 'react';
import './Content404.css';


function Content404() {
  const canvasWidth = 1200;
  const canvasHeight = 1200;
  const canvasRef = useRef(null);
  const [context, setContext] = useState(null);
  const dots = [];

  useEffect(() => {
    for (let i = 0; i < 15; i++) {
      const xPos = Math.floor(Math.random() * 800) + 200;
      const yPos = Math.floor(Math.random() * 300) + 500;
      const dot = { x: xPos, y: yPos, radius: 2, tick: 0};
      dots.push(dot);
    }
    setTimeout(function () {
      window.requestAnimationFrame(loop);
    }, 100)
  });

  useEffect(() => {
    setContext(canvasRef.current.getContext('2d'))
  }, [canvasRef])

  const update = () => {
    const dotLength = dots.length;
    for (let i = 0; i < dotLength; i++) {
      if (dots[i].tick > 5) {
        context.clearRect(0, 0, canvasWidth, canvasHeight)
        const moveX = ( Math.floor(Math.random()*3) - 1 ) * 2;
        const moveY = ( Math.floor(Math.random()*3) - 1 ) * 2;
        dots[i].x += moveX;
        dots[i].y += moveY;
        dots[i].tick = 0;
      } else {
        dots[i].tick += 1;
      }
    }
  };

  const draw = () => {
    const dotLength = dots.length;
    for (let i = 0; i < dotLength; i++) {
      context.beginPath();
      context.arc(dots[i].x, dots[i].y, dots[i].radius, 0, 2 * Math.PI, false);
      context.fillStyle = "#FFD700";
      context.shadowBlur = 15;
      context.shadowColor = "yellow";
      context.fill();
    }
  };

  const loop = () => {
    update();
    draw();
    window.requestAnimationFrame(loop);
  }

  return (
    <div className="content">
      <canvas width={canvasWidth} height={canvasHeight} ref={canvasRef} className="content__canvas"></canvas>
    </div>
  );
}

export default Content404;
